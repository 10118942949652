/* Adding !important to ai-dialog styles as lazy loading aurelia-dialog plugin is overriding these styles */
ai-dialog-overlay.active {
	background: rgba(83, 90, 102, 0.85) !important;
	z-index: 9999999 !important;
}

ai-dialog-container.active {
	z-index: 9999999 !important;
}

ai-dialog-container {
	display: flex !important;
	flex-direction: column !important;
	justify-content: center !important;
}

ai-dialog > ai-dialog-body {
	padding: 0 0 15px !important;
}

ai-dialog > ai-dialog-header {
	border-bottom: none !important;
	padding: 0 !important;
}

ai-dialog > ai-dialog-footer {
	border-top: none !important;
}

ai-dialog > ai-dialog-footer button:hover:enabled {
	background: var(--dark-aqua) !important;
	text-decoration: none !important;
	border: 1px solid var(--dark-aqua) !important;
	color: var(--white) !important;
}

ai-dialog-footer .btn-aqua:focus {
	color: var(--white) !important;
}

.fullpage-greybg {
	width: 100%;
}

.has-error input,
.has-error input[type="text"],
.has-error input[type="password"] {
	border-color: var(--orange);
}

.breadcrumb > li + li::before {
	content: "";
}

.panel-footer {
	padding: 0;
	background-color: transparent;
	border-top: none;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.dropdown-menu {
	border: 1px solid var(--aqua);
	border-top: solid 1px var(--aqua) !important;
}

.dropdown-item.selected {
	background-color: #f4f7f9;
}

.table > thead > tr > th {
	font-size: 14px;
	min-width: 120px;
}

.table > tbody > tr > td {
	word-wrap: break-word;
}

.table-column {
	min-width: 300px;
}

a.dropdown-toggle:hover {
	color: var(--grey-2);
}

.btn-aqua:hover {
	color: #f4f7f9;
}

.btn-aqua:focus {
	text-decoration: none;
	color: #f4f7f9;
}

.table > tbody > tr > td.open .dropdown-menu {
	width: 250px !important;
}

.panel-footer .paginationarrow li a:hover {
	border: solid 1px var(--aqua);
}

.form-container-white p span.tenant-org {
	font-weight: normal;
}

.show-item {
	display: block !important;
}

/*CM-526*/

/* catering for jquery */
.btn-group.bdrnone .btn-top .arrow.arrow-top,
.btn-group.bdrnone .btn-top .arrow.arrow-bot {
	display: block;
}

/*
  Nathans style doesn't allow for a second span in that location.
  Due to i18n, another element is needed.
  So used a <strong> element with custom styling.
*/
.device-no strong {
	font-weight: normal;
	text-transform: uppercase;
}

/*END CM-526*/

.doughnut {
	z-index: 99;
	position: absolute;
	left: -70px;
	top: -15px;
	width: 225px;
}

.legend {
	margin: 0 0 0 9px !important;
}

#data-chart .legend-text {
	left: 4px !important;
	top: 28px !important;
}

.legend-text {
	left: 3px;
}

.default-pointer {
	cursor: default;
}

.hardware-table-head {
	min-width: 155px;
}

.table-col {
	min-width: 380px;
}

/*CM-842*/
/*Catering for CSS changes in design that haven't been moved further*/

.profile-wrap {
	width: 50%;
	display: inline-block;
	float: left;
}

#user-profile-tab ul {
	margin: 0 20px 16px;
	padding: 0 0 22px 0;
	display: block;
	vertical-align: top;
	border-top: solid 1px var(--grey-5);
}

#user-profile-tab ul.profile li dd {
	min-width: 94px;
}

.open-content ul li:hover label,
.modalpopup-container.modal-padd.device-modal ul li:hover label,
.side-nav ul li:hover label,
.profile-wrap ul li:hover label,
ul.profile li:hover label {
	color: inherit !important;
}

/*END CM-842*/

/*CM-854*/
/*
 hand cursor not required on hover
*/
#device-active .table > tbody > tr:hover {
	cursor: default;
}

/*END CM-854*/

/*CM-828*/
/*Added until the main.css is updated to the latest*/

#device-slide-out #device-sidebar.side-nav .fix-height {
	overflow-y: auto !important;
}

/*END CM-828*/

#volumeCol dl dd span {
	width: auto;
	display: block;
	font-weight: 600;
}

#volumeCol dl dd {
	margin: 0 16px 0 0;
	padding: 0;
	display: inline-block;
	width: auto;
	min-width: 60px;
}

.inline {
	display: inline;
}

/*CM-764*/
/*Design does not cater for large data in rows, added this class
  to prevent the table from stretching the screen*/

.max-software-col {
	max-width: 250px;
}

/*END CM-764*/

/*CM-883*/

#data-chart .pie-chart__pie.categories {
	margin-left: 60px;
}

.legend li.files {
	width: 215px;
}

/*CM-883 END*/
//
//#slide-out-filter.equal_height {
//  position: absolute;
//  top: 0;
//  height: 100%;
//}

/*CM-849 START*/
.panel-primary .panel-body.open {
	display: block;
}

/*CM-849 END*/

/* CM-1016 */
/* remove extra spacing for checkbox */
#cibecs-table thead tr th:first-child.first {
	min-width: 20px !important;
	max-width: 30px;
}

/* CM-1016 END */
.nav-tabs .nav-item.inactive a {
	opacity: 0.5;
}

/* CM-993 */
.checkbox-img {
	width: 20px;
	height: 20px;
}

/* CM-993 END */
/*CM-759*/
/*These status icons were not catered for in device profile screen*/
.protected-aqua.mb-5,
.protected-unprotected.mb-5,
.protected-warning.mb-5,
.protected-inactive.mb-5,
.clock-o.mb-5,
.status-failed.mb-5,
.check-light-grey.mb-5 {
	margin: 0 10px -5px 0;
	padding: 0;
}

#device-slide-out .title > img {
	width: 25px;
	height: 25px;
}

/*END CM-759*/

/* CM-491 */
.policies ul li.icon span {
	margin: 8px 8% !important;
}

/* END CM-491 */

/*CM-10002 START*/

.mt22 {
	margin-top: 22px;
}

/*CM-10002 END*/
.sidebar-left.team-sidebar ul {
	overflow-y: auto !important;
}

.sidebar-left.team-sidebar ul li.active {
	background-color: var(--light-blue);
}

/* CM-1009 START */

.no-pen {
	background: none;
	width: 20px;
	height: 20px;
	display: inline-block;
}

/* CM-1009 END */

/* CM-1050 START */

.modalpopup-container .redio-btn-sec .radio:first-child {
	margin-left: 125px;
}

.modalpopup-container .redio-btn-sec .radio {
	width: 110px;
	display: inline-block;
}

/* START CM-1008 */

.no-edit-grey {
	width: 20px;
	height: 20px;
	display: inline-block;
}

/* END CM-1008 */

/*CM-1193*/
.loaded #loader-wrapper {
	visibility: hidden;
	opacity: 0;
	transition: all 0.3s 0.3s ease-out;
}

/*CM-1193 END*/

/* CM-1045 START */

.location-dropdown {
	top: 85% !important;
	left: auto !important;
}

/* CM-1045 END */

/*CM-1211*/
.device-white-container .licences img,
.device-white-container .licences svg {
	margin: 0 7px -4px 0;
}

/*CM-1211 END*/

/* CM-1092 */
.invitation-status {
	margin: 0 10px 0 0;
	height: 18px;
	width: 18px;
}

/*CM-1194*/
.modalpopup-container.dis-b {
	position: fixed;
	top: 50%;
	left: 0;
	right: 0;
	margin: -125px auto;
	z-index: 999999;
	height: auto;
}

.modalpopup-container h3.margint20,
.form-container-white h3.margint20 {
	margin-top: 20px;
}

.modalpopup-container h3.marginb10,
.form-container-white h3.marginb10 {
	margin-bottom: 10px;
}

.modalpopup-container h3,
.form-container-white h3 {
	font-size: 25px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.2;
	text-align: center;
	color: var(--grey-2);
	margin-bottom: 30px;
	clear: both;
	margin-top: 0;
}

.mrglr50 {
	margin: 0 50px;
}

/*CM-1194 END*/

a:hover svg .yellow {
	fill: var(--yellow);
}

.device-details-profile-heading {
	float: left;
	width: 100%;
}

a.close:hover svg path {
	fill: var(--aqua);
}

/* CM-1006 START */

.inline-edit-input {
	display: none;
	float: left;
	margin: -6px auto;
	width: 400px;
	border-radius: 3px;
	background-color: var(--white);
	border: solid 1px var(--grey-5);
	padding: 9px 10px;
	box-shadow: 0 0 3px 0 rgba(24, 197, 162, 0.2);
	background-color: var(--white);
	border-color: var(--aqua);
}

.inline-edit-input.focused {
	display: inline-block;
}

.inline-edit-input input {
	display: block;
	width: 100%;
	border: none;
	outline: none;
	border-radius: 0;
	padding: 0;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 16px;
	line-height: 1.38;
	color: var(--grey-1);
	-webkit-appearance: none;
	background-color: transparent;
}

.form-group.edit input[type="text"] {
	width: 300px;
	height: 40px;
}

.breadcrumb li.last i.pencil, .breadcrumb li.last .edit {
	margin: 3px 0 0 10px;
}

.form-group.edit {
	margin: -5px 0 0 0;
	padding: 0;
}

.error-text {
	margin: 0 0 0 20px;
	display: inline-block;
	color: var(--orange);
	font-size: 16px;
	font-stretch: normal;
	font-style: normal;
	font-weight: normal;
	line-height: 1.38;
}

.validation-error-text {
	margin: 0 0 0 20px;
	display: inline-block;
	color: var(--orange) !important;
	font-size: 16px !important;
	font-stretch: normal;
	font-style: normal;
	font-weight: normal;
	line-height: 1.38;
}

input.input-error[type="text"] {
	background: var(--light-blue);
	border: 1px solid var(--orange) !important;
	border-radius: 3px;
	box-shadow: 0 0 3px 0 rgba(24, 197, 162, 0.2);
	padding: 10px 10px;
	text-align: left;
	color: var(--grey-1);
}

/* CM-1006 END */

/* CM-1220 */
.inactive-header + .panel-body {
	visibility: hidden;
}

.active-header + .panel-body.open-content {
	visibility: visible;
}

/* CM-1220 END */

.loader {
	position: absolute;
	border-radius: 100px;
	box-shadow: none;
}

.loader.w200:after {
	right: 160px;
	animation-name: slide1;
}

/* CM-1046 START */

.files-icon-label {
	width: 14px;
	height: 14px;
	display: inline-block;
	vertical-align: top;
}

/* CM-1046 END */

/* CM-1249 START */

.w20 {
	width: 20px;
}

/* CM-1249 END */
/* CM-831 START*/
icon svg {
	fill: var(--grey-3);
	width: 20px;
	height: 20px;
}

icon svg:hover {
	fill: var(--aqua);
}

.disabled-action {
	fill: var(--grey-4);
}

.disabled-action:hover {
	fill: var(--grey-4);
}

/* CM-831 END*/
.btn-grey.btn-6 {
	padding-right: 20px;
}

.btn-grey.btn-6 {
	width: auto;
}

//.filter .expand {
//  height: auto;
//  max-height: 450px;
//  margin-bottom: 10px;
//}

/* CM-1040 START */

.top-dropdown {
	top: -95px !important;
}

/* CM-1040 END */

/* CM-1259 START */

.mh-none {
	max-height: none;
}

/* CM-1259 END */

.fill-grey {
	fill: var(--grey-3);
}

.mouse-cursor-link {
	cursor: pointer;
}

.text-aqua-bold {
	color: var(--aqua);
	font-weight: 600;
}

.text-yellow-bold {
	color: var(--yellow);
	font-weight: 600;
}

.text-blue-bold {
	color: var(--blue);
	font-weight: 600;
}

.text-dark-blue-bold {
	color: var(--dark-blue);
	font-weight: 600;
}

.normal-font-weight {
	font-weight: 400;
}

.background-yellow {
	background-color: var(--yellow);
}

.background-red {
	background-color: var(--red);
}

.background-grey {
	background-color: var(--grey-4);
}

.panel-primary .panel-body .panel-body_dtp {
	position: relative;
	padding: 20px 0 10px;
}

.bootstrap-tagsinput.has-error {
	border: solid 1px var(--orange);
}

.reset-password-success {
	padding-left: 10px;
	padding-right: 10px;
}

.table > tbody > tr.normal-row-cursor:hover {
	cursor: default;
}

.mrgr20 {
	margin-right: 20px;
}

.padl2 {
	padding-left: 2px;
}

.modalpopup-container.modal-padd.device-modal ul li span {
	min-width: 200px;
}

.text-yellow {
	color: #FFC107;
}

.dropdown-menu__title {
	padding: 0px 0px;
	font-size: 14px;
	font-weight: bold;
	color: var(--aqua);
}

.no-select {
	background: white;
	cursor: auto;
}

.dropdown-menu__noaccounts {
	padding-bottom: 11px;
}

ux-dialog-overlay.active {
	background-color: black;
	opacity: .4 !important;
}

.validation-message {
	max-height: 0;
	opacity: 0;
	margin-bottom: 0;
	overflow: hidden;
	white-space: normal;
	display: flex;
	flex-grow: 1;
	flex-direction: row;
	gap: 12px;
}

.form-group.has-error .validation-message {
	max-height: 30px;
	padding-top: 10px;
	opacity: 1;
	margin-bottom: 15px;
	animation: errorExpand .08s forwards ease-out;
}

.form-group.has-error.highlight-field-only .validation-message {
	margin: unset;
	padding: unset;
	animation: unset;
}

@keyframes errorExpand {
	0% {
		max-height: 0;
		opacity: 0;
		margin-bottom: 0;
	}
	60% {
		opacity: 0;
	}
	100% {
		max-height: 50px;
		opacity: 1;
		margin-bottom: 15px;
	}
}


.toggle-sec.acdetail .table {
	//margin:0;
	//padding-left:57px;
	//width:100%;
	tr {
		td:first-child {
			width: 25%;
		}

		&.validation-row {
			td {
				padding: 5px 0 !important;
			}
		}
	}
}

.validation-row {
	.validation-message {
		padding: 10px 0;
		margin-bottom: 0;
		animation: errorRowExpand .08s forwards ease-out;
	}
}

@keyframes errorRowExpand {
	0% {
		max-height: 0;
		opacity: 0;
	}
	60% {
		opacity: 0;
	}
	100% {
		max-height: 60px;
		opacity: 1;
	}
}

.validation-error-message {
	line-height: 19px;
	color: var(--orange);
}

.form-group.choose-domain .validation-error-message {
	line-height: 19px;
	color: var(--orange);
	font: 14px 'Source Sans Pro', sans-serif;
}

div .has-success input {
	border-color: var(--aqua);
}

.top-error-message-space {
	margin-top: 20px;
}

ai-dialog {
	display: table;
	box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
	border: 1px solid rgba(0, 0, 0, .2);
	border-radius: 5px;
	padding: 3;
	min-width: 300px;
	width: -moz-fit-content;
	width: -webkit-fit-content;
	width: fit-content;
	height: -moz-fit-content;
	height: -webkit-fit-content;
	height: fit-content;
	margin: auto;
	border-image-source: initial;
	border-image-slice: initial;
	border-image-width: initial;
	border-image-outset: initial;
	border-image-repeat: initial;
	background: #fff;
}

.padl30 {
	padding-left: 30px;
}

#user-profile-tab ul li span {
	width: 240px;
	display: inline-block;
	vertical-align: top;
}

.fill-red {
	color: #FD5000;
}

.policies ul li.policy-first-item {
	width: 30%;
	min-width: 409px;
	padding-top: 7px;
}

.policies ul li.ad-first-item {
	width: 30%;
	min-width: 409px;
	margin-top: -4px;
}

/*  Fill */
.fill-aqua {
	//fill: #19C5A2;
	color: var(--aqua);
}

.fill-yellow {
	//fill: #FFC107;
	color: var(--yellow);
}

.fill-warning {
	color: var(--action-warning);
}

.fill-light-silver {
	color: #c6ced8;
}

.fill-silver {
	color: #8493A9; //TODO use vars
}

.fill-transparent {
	color: transparent;
}

.fill-dark-silver {
	color: #787D84;
}

.fill-orange {
	color: #FD5000; // TODO use vars
}

.fill-red {
	color: #e71a4c;
}

.fill-blue {
	color: #0c9fed;
}

.fill-dark-blue {
	color: var(--dark-blue);
}


.fill-white {
	color: var(--white);
}

/* Hover */
.hover-aqua:hover svg {
	color: var(--aqua);
}

.hover-orange:hover svg {
	color: #FD5000;
}

.hover-white:hover svg {
	color: var(--white);
}

.hover-yellow:hover svg {
	color: var(--yellow);
}

.wh-120-28 {
	width: 120px;
	height: 28px;
}

.wh-160-40 {
	width: 160px;
	height: 40px;
}

.wh-325-385 {
	width: 325px;
	height: 385px;
}

.wh-313-283 {
	width: 313px;
	height: 283px;
}

.wh-402-299 {
	width: 402px;
	height: 299px;
}

.wh-378-329 {
	width: 378px;
	height: 329px;
}

.wh-358-298 {
	width: 358px;
	height: 329px;
}

svg {
	fill: currentColor;
	vertical-align: top;
}

.devices-breadcrumb-arrow-right {
	margin: 4px 12px 0 12px;
}

header .user > a:hover svg,
header .user.open > a svg {
	display: none;
}

// Hide Lastpass Icon
#__lpform_input_idx_0 {
	display: none;
}

.image-spin {
	-webkit-animation: spin 4s linear infinite;
	-moz-animation: spin 4s linear infinite;
	animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.h2-icon {
	line-height: 20px;
	display: inline-block;
}

.wh-120 {
	width: 120px;
	height: 120px;
	margin-left: 40px;
	margin-top: 40px;
}

.account-slider__relative {
	position: relative;
	margin-bottom: 40px;
}

.account-login__slider-inactive {
	position: absolute;
	top: 0;
	width: 100%;
	animation: carouselSlideOut 1s forwards ease;
}

.account-login__slider-inactive--right {
	animation: carouselSlideOutRight 1s forwards ease;
}

.account-login__slider-active {
	position: relative;
	top: 0;
	animation: carouselSlideIn 1s forwards ease;
}

.account-login__slider-active--right {
	animation: carouselSlideInRight 1s forwards ease;
}


@keyframes carouselSlideOut {
	0% {
		left: 0;
		opacity: 1;
	}
	100% {
		left: -200%;
		opacity: 0;
	}
}

@keyframes carouselSlideOutRight {
	0% {
		right: 0;
		opacity: 1;
	}
	100% {
		right: -200%;
		opacity: 0;
	}
}

@keyframes carouselSlideIn {
	0% {
		left: 200%;
		opacity: 0;
	}
	100% {
		left: 0;
		opactiy: 1;
	}
}

@keyframes carouselSlideInRight {
	0% {
		right: 200%;
		opacity: 0;
	}
	100% {
		right: 0;
		opactiy: 1;
	}
}


ul.carousel-dots {
	position: absolute;
	bottom: -45px;
	width: 100%;
	text-align: center;

	li {
		display: inline-block;
		width: 13px;
		height: 13px;

		span {
			width: 6px;
			height: 6px;
			display: inline-block;
			border-radius: 10px;
			text-align: left;
			text-indent: -9999px;
		}
	}
}

.carousel-dots-silver {
	background-color: var(--grey-4);
}

.carousel-dots-darksilver {
	background-color: var(--grey-3);
}

.account-login__slider-wrap {
	overflow: hidden;
}

.inactive {
	color: var(--grey-3);
}

ul.au-target.inactive div {

	label {
		color: var(--grey-3);
	}

	li {
		color: var(--grey-3);

		span {
			color: var(--grey-3);

			&.text-aqua-bold {
				color: var(--aqua);
				font-weight: 600;
			}

			&.text-yellow {
				color: var(--yellow);
			}

			&.text-orange-bold {
				color: var(--orange);
			}
		}

		label {
			color: var(--grey-3);
		}
	}
}

ul.first.inactive div {
	label {
		color: var(--grey-3);
	}
}

ul.inactive {
	label {
		color: var(--grey-3);
	}

	div {
		color: var(--grey-3);
	}

	li {
		color: var(--grey-3);

		span {
			color: var(--grey-3);
		}

		label {
			color: var(--grey-3);
		}

		dd {
			span {
				color: var(--grey-3);
			}

			label {
				color: var(--grey-3);
			}
		}
	}
}

.information__text {
	color: var(--red);
}

.user .dropdown-menu .dropdown-item.dropdown-item__container_icon_right {
	padding-right: 49px;
}

.dropdown-item__icon_right_align {
	margin: 0 0 0 10px !important;
	color: var(--aqua);
	position: absolute;
	right: 19px;
}

.dropdown-menu__accounts a.dropdown-item:hover svg {
	color: var(--white);
}

.margint2 {
	margin-top: 2px;
}


.spinner {
	display: inline-block;
	white-space: nowrap;
	width: 40px;
	text-align: center;
}

.spinner > div {
	width: 8px;
	height: 8px;
	margin: 0 2px;
	background-color: var(--grey-3);
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%, 80%, 100% {
		-webkit-transform: scale(0)
	}
	40% {
		-webkit-transform: scale(1.0)
	}
}

@keyframes sk-bouncedelay {
	0%, 80%, 100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1.0);
		transform: scale(1.0);
	}
}

.text-orange-bold {
	color: var(--orange);
}

.marginr5 {
	margin-right: 5px;
}

.marginlN1 {
	margin-left: -1px;
}

.marginl10 {
	margin-left: 10px;
}

.marginr10 {
	margin-right: 10px;
}

.paddt10 {
	padding-top: 10px;
}


.toggle-sec .timezone-picker {
	tr {
		td {
			padding: 2px 0;

			.btn-select {
				margin-bottom: 0;
			}
		}
	}
}

.timezone-picker {
	border-spacing: 0;

	tr {
		td {
			padding: 0;

			.btn-select {
				min-width: 0;
				width: 195px;
			}

			label {
				font-weight: 400;
			}
		}
	}
}

.inline-timezone-picker {
	display: none;
	float: left;
	margin: -6px auto;
	border-radius: 3px;
	background-color: var(--white);
	border: solid 1px var(--grey-5);
	padding: 9px 10px;
	box-shadow: 0 0 3px 0 rgba(24, 197, 162, 0.2);
	background-color: var(--white);
	border-color: var(--aqua);
	width: 514px;
	position: relative;

	&__btn {
		width: 80px;
		position: absolute;
		right: 20px;
		bottom: 16px;
	}

	&__close {
		width: 12px;
		position: absolute;;
		right: 10px;
		top: 10px;
	}
}

.inline-timezone-picker.focused {
	display: inline-block;
}


.form-group {
	&--msg {
		max-height: 0;
		opacity: 0;
		overflow: hidden;
		transition: .2s ease-out;
	}

	&--expand {
		max-height: 28px;
		opacity: 1;
	}
}

.inline-select {
	display: none;
	float: left;
	margin: -6px auto;
	border-radius: 3px;
	background-color: var(--white);
	border: solid 1px var(--grey-5);
	padding: 8px 0px;
	box-shadow: 0 0 3px 0 rgba(24, 197, 162, 0.2);
	background-color: var(--white);
	border-color: var(--aqua);
	position: relative;
	width: 515px;

	&__btn {
		width: 80px;
		position: absolute;
		right: 20px;
		bottom: 13px;
	}

	&__close {
		width: 12px;
		position: absolute;;
		right: 10px;
		top: 10px;
	}

	&__text {
		margin: 2px 0 5px 10px;
	}

	&__display-value {
		display: inline-block;
		height: 18px;
	}

	a {
		margin-bottom: 10px;
	}
}

.inline-select.focused {
	display: inline-block;
}

.inline-datetimepicker {
	display: none;
	float: left;
	margin: -6px auto;
	border-radius: 3px;
	background-color: var(--white);
	border: solid 1px var(--grey-5);
	padding: 8px 0px;
	box-shadow: 0 0 3px 0 rgba(24, 197, 162, 0.2);
	background-color: var(--white);
	border-color: var(--aqua);
}

.inline-datetimepicker.focused {
	display: inline-block;
}

.wh-263 {
	width: 263px;
	height: 362px;
}

.table tr td .edit {
	float: right;
}

.license-expired {
	margin: 0 auto;
	position: relative;
	text-align: center;

	&_message {
		font-size: 40px;
		line-height: 60px;
		display: inline-block;
		margin-right: 70px;
	}

	&_expire__date {
		margin: 0 auto;
		width: 150px;
	}

	&_contact_sales {
		margin: 0 auto;
		width: 150px;
		padding-top: 30px;
	}

}

.breadcrumb_separator {
	margin-left: 5px;
	margin-right: 5px;
}

.breadcrumb li.last span {
	font-weight: bold;
}

span.capsule.capsule--grey-3 {
	margin-left: 8px;
}

.padding-top-zero {
	padding-top: 0;
}

#detail > div > div > table > tbody > tr:nth-child(2) > td:nth-child(2) > abp-datetime-picker > div > input {
	display: none;
}

input.form-control.datetimepicker.au-target {
	display: none;
}

.display-block {
	display: block;
}

.display-none {
	display: none;
}

.policies ul li.ad-text-item {
	margin-top: -4px;
}

// Search input

.table-search-input {
	padding: 0;
	border: none;
	border-radius: 0;
	background-color: var(--white);
	float: left;
	width: auto;

	input {
		border: 0;
		box-shadow: none;
		background: var(--white);
		//@extend .sans-1-regular;
		line-height: 1.25;
		color: var(--grey-1);
		text-align: left;
		padding: 0;
		height: 40px;
		width: auto;
	}

	button {
		margin: -5px 0 0;
		background: none;
		box-shadow: none;
		border: 0;
		color: var(--grey-3);
		padding: 0 8px;
		border: none;

		&:hover {
			border: 0;
			box-shadow: none;
			border: none;
		}
	}

	&.left {
		width: 85px;

		input {
			background: url(../../images/icn-search.svg) no-repeat 10px center;
			width: 0;
			padding: 0 18px;

			&:hover {
				background: url(../../images/icn-search-hover.svg) no-repeat 10px center;
				width: 0;
				padding: 0 18px;
			}

			&:focus {
				background: #F4F8FA url("../../images/icn-search-close.svg") no-repeat right 11px center;
				border-radius: 3px;
				transition: background 0s ease 0s;
				width: 250px;
				border: solid 1px var(--aqua);
				height: 40px !important;
				margin: 0 0 0 0;
				padding: 0 30px 0 10px;
				color: var(--grey-1);
				text-align: left;
			}
		}
	}
}

.table-search-input.right {
	padding: 0;
	border: none;
	border-radius: 0;
	background-color: var(--white);
	float: left;
	height: 40px;

	input {
		background: url(../../images/icn-search.svg) no-repeat 12px center;
		border: solid 1px transparent;
		padding: 0 30px 0 13px;
		width: 0px;
		margin: -3px 0 0 0;

		&:hover {
			cursor: pointer;
			background: url(../../images/icn-search-hover.svg) no-repeat 12px center;
		}

		&:focus {
			border: solid 1px transparent;
			box-shadow: none;
		}

		&.focused {
			background: #F4F8FA url("../../images/icn-search-close.svg") no-repeat right 11px center;
			border-radius: 3px;
			transition: background 0s ease 0s;
			width: 250px;
			border: solid 1px var(--aqua);
			height: 40px !important;
			margin: -3px 0 0 0;
			padding: 0 30px 0 10px;
			color: var(--grey-1);
			text-align: left;
		}
	}

	#settings-search-close-overlay {
		background-color: transparent;
		width: 20px;
		height: 20px;
		z-index: 1000;
		margin-top: -30px;
		position: relative;
		display: none;

		&.focused {
			cursor: pointer;
			margin-left: 220px;
			display: block;
		}
	}

}

.table-search-input .input-group {
	margin: 0 10px 0 0;
}

#vault-settings-box {
	float: right;
	position: relative;

	& div#setting {
		float: left;
	}
}

.table2 td.bold {
	font-weight: 600;
}

.page_container {
	height: 100%;
	width: 100%;
}

.text25-orange {
	font-size: 25px;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: var(--orange);
}

.modal-geolocate {
	width: 1000px;
	height: 500px;
}

#device-slide-out .sidebar-btn .shedow a .tooltip {
	top: -20px !important;
}

.wh-360-180 {
	width: 360px;
	height: 180px;
}

.center-503 {
	position: absolute;
	top: 150px;
	width: 600px;
	height: 389px;
	margin: 0 0 0 -300px;
	left: 50%;
}

.wh-24 {
	width: 24px;
	height: 24px;
}

.margint100 {
	margin-top: 100px;
}

.geolocate_device_name {
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	font-weight: 700;
	line-height: 1.38;
	color: var(--grey-2);
}

.padding-top-250 {
	padding-top: 250px;
}

.padding-top-120 {
	padding-top: 120px;
}

.wh-26 {
	width: 26px;
	height: 26px;
}

.padding-left-14 {
	padding-left: 14px;
}

.bold {
	font-weight: 600;
}

.tooltip-inner {
	max-width: none;
	white-space: nowrap;
}

.migration-animation {
	-webkit-animation: migration-anim 1.5s linear infinite;
	-moz-animation: migration-anim 1.5s linear infinite;
	animation: migration-anim 1.5s linear infinite;
}

@-moz-keyframes migration-anim {
	0% {
		color: #8493A9;
	}
	50% {
		color: #0C9FED;
	}
	100% {
		color: #8493A9;
	}
}

@-webkit-keyframes migration-anim {
	0% {
		color: #8493A9;
	}
	50% {
		color: #0C9FED;
	}
	100% {
		color: #8493A9;
	}
}

@keyframes migration-anim {
	0% {
		color: #8493A9;
	}
	50% {
		color: #0C9FED;
	}
	100% {
		color: #8493A9;
	}
}

.migration-progress-animation {
	-webkit-animation: migration-progress-anim 3s linear infinite;
	-moz-animation: migration-progress-anim 3s linear infinite;
	animation: migration-progress-anim 3s linear infinite;
}

@-moz-keyframes migration-progress-anim {
	0% {
		color: #ffffff;
	}
	50% {
		color: #0C9FED;
	}
	100% {
		color: #ffffff;
	}
}

@-webkit-keyframes migration-progress-anim {
	0% {
		color: #ffffff;
	}
	50% {
		color: #0C9FED;
	}
	100% {
		color: #ffffff;
	}
}

@keyframes migration-progress-anim {
	0% {
		color: #ffffff;
	}
	50% {
		color: #0C9FED;
	}
	100% {
		color: #ffffff;
	}
}

.text-overflow-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 330px;
	display: inline-block;
	vertical-align: middle;
}

.ml-10 {
	margin-left: 10px;
}

.migration-list-entry-warn {
	line-height: 20px;
	vertical-align: middle;
}

.nowrap {
	white-space: nowrap;
}

.migration-detail-error-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 570px;
	display: inline-block;
	vertical-align: middle;
}

.pad-left3 {
	padding-left: 3px;
}

.email_team_scrollable {
	max-height: 120px;
	overflow-y: auto;
}

.inner-search-box-holder {
	padding-bottom: 10px;

	input {
		padding: 5px;
		font-size: 14px;
	}

	button {
		margin: -5px 0 0;
		background: none;
		box-shadow: none;
		border: 0;
		color: var(--grey-3);
		padding: 0 8px;
		border: none;
	}

	&.right {
		input {
			background: #F4F8FA url("../../images/icn-search-close.svg") no-repeat right 11px center;
			border-radius: 3px;
			border: solid 1px var(--aqua);
			height: 30px !important;
			margin: 0 0 0 0;
			padding: 0 30px 0 10px;
			color: var(--grey-1);
			text-align: left;

			cursor: pointer;
			display: block;
		}
	}

	#inner-search-close-overlay {
		background-color: transparent;
		width: 20px;
		height: 20px;
		z-index: 1000;
		margin-top: -25px;
		position: relative;
		display: none;
		float: right;
		margin-right: 14px;

		&.focused {
			cursor: pointer;
			margin-left: 220px;
			display: block;
		}
	}

}

.text-overflow-ellipsis-selection {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 398px;
	display: inline-block;
	vertical-align: middle;
}

.paddlr52 {
	padding: 0 52px;
}

.marginb5 {
	margin-bottom: 5px;
}

.dlp-white-container {
	background-color: var(--white);
	border-radius: 3px;
	margin: 0px 1% 0 0;
	padding: 16px 16px;
	height: auto;
	box-shadow: none;
	display: inline-block;
	text-align: center;
	width: 30%;
}

.dlp-center {
	&:first-child {
		padding: 0 0 9px 30%;
	}

	&:last-child {
		padding: 9px 0 0 30%;
	}

	img, svg {
		float: left;
		margin: 5px 12px 0 0;
	}

	p {
		font-size: 12px;
		font-weight: normal;
		line-height: 1;
		color: var(--grey-1);
		text-align: left;
		margin: 0;

		&.nomber {
			font-size: 16px;
			font-weight: 600;
			line-height: 1.25;
		}
	}

	&.bdr {
		border-top: solid 1px var(--grey-5);
		border-bottom: solid 1px var(--grey-5);
		padding: 9px 0 9px 30%;
	}
}

.padding-bottom-20 {
	padding-bottom: 20px;
}

.padding-right-10 {
	padding-right: 10px;
}

.padding-left-5 {
	padding-left: 5px;
}

.padding-top-20 {
	padding-top: 20px;
}

.tenant-search-box {
	padding-bottom: 10px;

	input {
		padding: 5px;
		font-size: 14px;
		width: 230px;
	}

	button {
		margin: -5px 0 0;
		background: none;
		box-shadow: none;
		border: 0;
		color: var(--grey-3);
		padding: 0 8px;
		border: none;
	}

	&.right {
		input {
			background: #F4F8FA url("../../images/icn-search-close.svg") no-repeat right 11px center;
			border-radius: 3px;
			border: solid 1px var(--aqua);
			height: 30px !important;
			margin: 0 0 0 0;
			padding: 0 30px 0 10px;
			color: var(--grey-1);
			text-align: left;
			cursor: pointer;
			display: block;
		}
	}

	#tenant-search-close-overlay {
		background-color: transparent;
		width: 20px;
		height: 20px;
		z-index: 1000;
		margin-top: -25px;
		position: relative;
		display: none;
		margin-right: 7px;

		&.focused {
			cursor: pointer;
			margin-left: 198px;
			display: block;
		}
	}

}

.logo-max-size {
	width: max-content;
	height: max-content;
}

.breadcrumb {
	height: 30px;
}

.table-horizontal-heading {
	font-weight: 600;
	font-size: 14px;
}
.vault-info {
	display: contents;
	&__alias {
		font-weight: 700;
		color: var(--grey-1);
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 20px;
	}
	&__status {
		color: var(--grey-3);
		line-height: 1.8;
		font-size: 12px;
		font-weight: 600;
		display: inline-block;
		span {
			color: var(--grey-3);
		}
		&__online {
			color: var(--aqua) !important;
		}
	}
	&__region {
		display: flex;
		min-width: 15%;
		align-items: center;
		flex: 30 0 50px;
		span {
			overflow: hidden;
			text-overflow: ellipsis;
			padding-right: 20px;
		}
	}
}

#vault_detail_table tr td .edit {
	position: relative;
}

.vault-retention-setting-help-text {
	font-style: italic;
}

.vault-status-message-text {
	font-style: italic;
}

.padding-top-25 {
	padding-top: 25px;
}

.height-64 {
	height: 64px;
}

.transparent-background {
	background-color: inherit !important;
}

.w70 {
	width: 70px !important;
}

.inventory-no-device-center {
	width: 100%;
	text-align: center;
}

#inventory-no-device-badge {
	margin-top: 41px;
}

.inventory-no-devices-welcome {
	text-align: center;
	width: 100%;
	font-size: 40px;
	font-weight: 300;
	color: var(--grey-1);
	height: 50px;
	margin-top: 20px;
}

.inventory-no-devices-started {
	text-align: center;
	width: 100%;
	color: var(--grey-1);
	height: 26px;
	font-size: 20px;
	font-weight: normal;
	margin-top: 10px;
}

.inventory-no-devices-spacer {
	height: 40px;
	width: 100%;
}

.inventory-no-devices-detail-container {
	width: 100%;
	display: flex;
	justify-content: center;

}

.inventory-no-devices-detail-deploy {
	width: 470px;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	box-shadow: 0 1px 3px 0 var(--shadow);
	background-color: var(--white);
}

.inventory-no-devices-detail-svg {
	margin-top: 40px;
	height: 120px;
}

.inventory-no-devices-detail-invite {
	width: 470px;
	border-radius: 5px;
	box-shadow: 0 1px 3px 0 var(--shadow);
	background-color: var(--white);
}

.inventory-no-devices-detail-divider {
	width: 40px;
}

.inventory-no-devices-detail-option {
	margin-top: 20px;
	color: var(--aqua);
	font-size: 16px;
}

.inventory-no-devices-detail-heading {
	margin-top: 10px;
	color: var(--grey-1);
	font-size: 30px;
	font-weight: 300;
}

.inventory-no-device-detail-content {
	margin-top: 20px;
	font-size: 14px;
	font-weight: normal;
	display: flex;
	justify-content: center;
	color: var(--grey-1);
}

.inventory-no-device-detail-content div {
	width: 390px;
}

.inventory-no-device-detail-button {
	margin-top: 20px;
	margin-bottom: 40px;

}

.user_invite_dialog_body {
	width: 600px;
	height: 440px;
}

.user_invite_row {
	display: flex;
}

.user_invite_row:after {
	content: "";
	clear: both;
}

.user_invite_close:hover {
	fill: var(--dark-orange)
}

.user_invite_add_another {
	text-decoration: none;
	color: var(--aqua);
}
.user_invite_add_another:hover {
	text-decoration: none;
	color: var(--aqua);
}
.user_invite_scroll {
	height: 295px;
	overflow-y: auto;
}
.user_invite_row_container {
	display: flex;
	flex-direction: column;
}
.user_invite_row_section {
	display: flex;
	flex-direction: column;
	float: left;
	width: 100%;
	height: 95px;
	margin-bottom: 5px;
}
.user_invite_names_container {
	display:flex;
}
.user_invite_fname_container {
	width: 50%;
}
.user_invite_fname_input {
	width: 96% !important;
}
.user_invite_lname_container {
	display:flex;
	flex:auto;
	width: 50%;
}
.user_invite_lname_input {
	width: 100% !important;
}
.user_invite_email_input_container {
	display:flex;
	width:100%;
}
.user_invite_email_input {
	margin: 11px 0 0 0 !important;
	width: 100% !important
}
.user_invite_remove_button {
	height: 95px;
	width: 26px;
	background-color: #e0e4e9;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	margin: 0 5px 0 5px;
	border-radius: 3px;
}
.user_invite_add_another_section {
	display: flex;
	float: left;
	flex-direction: column;
}
.user_invite_add_another_text {
	color: var(--aqua);
	font-weight: bold;
}

.user_invite_error_section {
	margin-bottom: 10px;
	margin-left: 5px;
	width: 500px;
	flex: content;
	display: flex;
	flex-direction: column;
}

.user_invite_dialog_buttons {
	display: flex;
	flex-direction: column;
}

.valid-field input {
	border: 1px solid var(--aqua);
}

.user_invite_error_section_container {
	padding-top: 5px;
	padding-bottom: 5px;
}

.paddr-5 {
	padding-right: 5px;
}
.height-20 {
	height: 20px;
}

.center {
	text-align: center;
}

.not-available {
	font-weight: 600;
	font-size: 20px;
	color: var(--grey-4);
}

.font-weight-400 {
	font-weight: 400 !important;
}

.ml-5 {
	margin-left: 5px;
}

// --------------------- 1

#emailActivationDialog > ux-dialog-header {
	font-size: 20px;
	background-color: #F9FBFC;
	font-weight: bold;
	color: #353d4b;
}

#emailActivationDialog > ux-dialog-body > div.container-table {
	width: 100%;
	user-select: none;
	position: relative;
	padding-top: 0px;
	box-sizing: border-box;
	display: block;
	overflow-y: auto;
	height: 300px;
}

#emailActivationDialog > ux-dialog-header > div {
	margin: 0 auto;
	height: 21px;
}

#emailActivationDialogTitle {
	float: left;
}

#emailActivationDialogClose {
	float: right;
}

.email-activation-dialog-user {
	margin-left: 5px;
	font-weight: 600;
}

.email-activation-dialog-error {
	display: inline-block;
	color: var(--orange);
	font-stretch: normal;
	font-style: normal;
	font-weight: normal;
	line-height: 1.38;
}

.email-activation-dialog-valid {
	color: var(--aqua);
}

.email-activation-dialog-error-row {
	background-color: #FFEEE6;
}

.email-activation-device-list {
	position: relative;
	display: block;
}

#email-activation-table > thead > tr > th {
	min-width: 10px;
}

.dis-flex {
	display: flex;
}

.padding-bottom-3 {
	padding-bottom: 3px;
}

ux-dialog>ux-dialog-body {
	padding-bottom: 0 !important;
}

.valign-middle {
	vertical-align: middle;
}

.inline-message {
	display: block;
	margin: 15px 0;
	position: relative;
	min-width: 300px;
	padding: 5px 14px 5px 34px;

	border-radius: 4px 4px;
	background-position: 7px center;
	background-repeat: no-repeat;
	background-size: 18px 18px;

	&__msg {
		text-align: left;
		font-style: normal;
		line-height: 1.45;

		.link {
			font-weight: bold;
		}
	}

	&--info {
		color: #0C9FED;
		background-color: #E1F3FC;
		background-image: url(../../images/messages/icn-info.png);
	}
}

#right-table {
	flex:1 0 550px;
}

.trademark {
	display: flex;
	flex-direction: column;
	color: var(--grey-3);
	padding-top: 5px;
}

.disabled-hover-tooltip {
	cursor: default;
	opacity: 0.5;
}

.connector-download {
	width: 180px;
	height: 150px;
	flex-grow: 0;
	object-fit: contain;
	fill: transparent;
}

.connector-auth-type-label {
	display: inline-block;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
	border-radius: 100px;
	border: solid 1px #8493a9;
	text-align: center;
	pointer-events: none;
	color: #8493a9;
	margin-right: 10px;
	padding: 3px 10px 3px 10px;
}

.background-grey-1 {
	background-color: var(--grey-1);
}

.background-grey-2 {
	background-color: var(--grey-2);
}

.background-grey-3 {
	background-color: var(--grey-3);
}

.background-grey-4 {
	background-color: var(--grey-4);
}

.background-grey-5 {
	background-color: var(--grey-5);
}
